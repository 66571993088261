import BuyArea from '../../components/buy-area';
import { useDocument } from '../../context/document-context';
import { Preview } from './components/preview';
import SuggestionsBrowser from './components/suggestions-browser';
import { WordLimitFooter } from './components/word-limit-footer';
import * as s from './editor-workspace.styles';

const EditorWorkspace = () => {
  const { state } = useDocument();

  return (
    <>
      <s.asideLeft />
      <s.editor>
        {state.context.preview && state.context.sessionId && (
          <Preview text={state.context.preview} sessionId={state.context.sessionId} />
        )}
        <WordLimitFooter hasMoreText={Boolean(state.context.hasMoreText)} />
      </s.editor>

      <s.asideRight>
        <s.asideRightInner>
          <SuggestionsBrowser />
          <BuyArea />
        </s.asideRightInner>
      </s.asideRight>
    </>
  );
};

export default EditorWorkspace;
